export const titleBannedFillerWords = [
  // german
  'sofort',
  'gesucht',
  'dringend',

  // french
  'immédiatement',
  'recherché',
  'urgent',

  // english
  'immediate',
  'required',
  'urgent',
];
