import { ContactPhoneTypeEnum } from '../../../../graphql/generated';
import i18next from 'i18next';

export const getPhoneOptions = () => {
  return [
    {
      value: ContactPhoneTypeEnum.PRIVATE,
      label: i18next.t('ContactPhoneTypeEnum.PRIVATE'),
    },
    {
      value: ContactPhoneTypeEnum.BUSINESS,
      label: i18next.t('ContactPhoneTypeEnum.BUSINESS'),
    },
  ];
};
