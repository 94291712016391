import { Component, inject, input, OnInit } from '@angular/core';
import {
  ContextMenuModule,
  DatePickerModule,
  InfoBoxModule,
  InputTextModule,
  ResponsiveService,
  SwitchModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TalentFormEditService } from '../../../services/talentFormEdit.service';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { SharedDefaultModule } from '../../../../shared-default/shared-default.module';
import {
  ArrayActionEnum,
  ContactPhoneTypeEnum,
} from '../../../../../../graphql/generated';
import { YearToAgePipe } from '../../../../../helpers/pipes/yearToAge.pipe';
import { randomId } from '../../../../../helpers/functions/randomId';
import { getPhoneOptions } from '../../../helpers/getPhoneOptions';
import { isValidPhoneNumber } from '../../../../shared-forms/customValidators';
import { TalentEmailPhoneService } from '../../../services/talent-email-phone.service';
import { PhonesFormGroup } from '../../../talent-sheet.types';
import { getFormData } from '../../../../../helpers/functions/getFormData';
import { filter } from 'rxjs/operators';
import {
  AvatarComponent,
  ButtonOutlineComponent,
  ButtonTextComponent,
  FormFieldComponent,
  ModalComponent,
  ModalService,
  SimpleSelectComponent,
  TextInputComponent,
  ToastService,
} from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-phones-entry',
  templateUrl: './talent-phones-entry.component.html',
  imports: [
    AvatarComponent,
    DatePickerModule,
    I18NextModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedDefaultModule,
    SwitchModule,
    YearToAgePipe,
    ModalComponent,
    ContextMenuModule,
    InfoBoxModule,
    UjSelectInputModule,
    ButtonTextComponent,
    ButtonOutlineComponent,
    FormFieldComponent,
    TextInputComponent,
    SimpleSelectComponent,
  ],
})
export class TalentPhonesEntryComponent implements OnInit {
  formService = inject(TalentFormEditService);
  sharedService = inject(TalentEmailPhoneService);
  i18next = inject(I18NextPipe);
  toastService = inject(ToastService);
  responsiveService = inject(ResponsiveService);
  modals = inject(ModalService);

  phonesFormArray = this.formService.phonesFormArray;

  deleteConfirmationModalId = 'deleteConfirmation' + randomId();

  phoneTypeOptions = getPhoneOptions();

  isSmDown$ = this.responsiveService.isSmDown$;
  isFirstEntry = input(false);
  isNewEntry = input(false);
  phoneGroup = input.required<FormGroup<PhonesFormGroup>>();

  showInputError = false;
  componentId = randomId();

  ngOnInit() {
    this.phoneGroup()
      .controls.phoneType.valueChanges.pipe(
        filter(() => {
          return this.phoneGroup().controls.phoneType.dirty;
        }),
      )
      .subscribe(() =>
        this.updatePhone(this.phoneGroup(), ArrayActionEnum.CHANGED),
      );
  }

  setPrimaryEmail() {
    this.sharedService.setPrimaryEntry(
      this.phoneGroup(),
      this.phonesFormArray,
      'phones',
      (newPrimaryUuid) => {
        this.sharedService.createToast(ArrayActionEnum.CHANGED);
        this.toastService.makeToast({
          type: 'INFO',
          message: this.i18next.transform('primaryPhoneUpdated'),
          duration: 6000,
        });
        this.formService.setPrimaryPhoneFirst(newPrimaryUuid);
      },
    );
  }

  deletePrimaryPhone() {
    this.sharedService.deletePrimaryEntry(
      this.phoneGroup(),
      this.phonesFormArray.at(1),
      this.phonesFormArray,
      'phones',
      () => {
        this.sharedService.createToast(ArrayActionEnum.REMOVED);
        this.toastService.makeToast({
          type: 'INFO',
          message: this.i18next.transform('primaryPhoneUpdated'),
          duration: 6000,
        });
      },
    );
    this.modals.close(this.deleteConfirmationModalId);
  }

  deletePhone() {
    const isPrimary = this.phoneGroup().controls.isPrimary.value;
    if (isPrimary && this.phonesFormArray.length > 1) {
      this.modals.open(this.deleteConfirmationModalId);
      return;
    }
    this.updatePhone(this.phoneGroup(), ArrayActionEnum.REMOVED);
    this.phonesFormArray.removeAt(
      this.phonesFormArray.controls.indexOf(this.phoneGroup()),
    );
  }

  addNewPhone() {
    this.updatePhone(this.phoneGroup(), ArrayActionEnum.ADDED);
    this.phoneGroup().reset({
      uuid: randomId(),
      type: ArrayActionEnum.ADDED,
      phone: null,
      isPrimary: false,
      phoneType: ContactPhoneTypeEnum.PRIVATE,
    });
  }

  onPhoneChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.phoneGroup().controls.phone.setErrors(null);

    const isValidNumber = isValidPhoneNumber(value);
    if (!isValidNumber) {
      this.phoneGroup().controls.phone.setErrors({
        message: {
          key: 'invalidPhoneNumber',
        },
        onlyOneSeparatorAllowed: true,
      });
      this.showInputError = true;
    } else {
      this.showInputError = false;
    }

    if (!isValidNumber) return;

    if (this.isNewEntry()) {
      this.addNewPhone();
    } else {
      this.updatePhone(this.phoneGroup(), ArrayActionEnum.CHANGED);
    }
  }

  updatePhone(
    childControl: FormGroup<PhonesFormGroup>,
    arrayAction: ArrayActionEnum,
  ) {
    const phonesData = getFormData(childControl);
    const talentUuid = this.formService.talent()?.uuid;

    if (!talentUuid || !phonesData) {
      console.error('Talent uuid is not valid');
      return;
    }

    phonesData.type = arrayAction;

    if (arrayAction === ArrayActionEnum.ADDED) {
      const currentPhones = this.formService.talent()?.phones ?? [];
      if (currentPhones.length === 0) {
        phonesData.isPrimary = true;
      }

      this.formService.addNewPhone({ ...phonesData });
    }

    this.formService
      .updateTalentApi({
        phones: [phonesData],
      })
      .subscribe((res) => {
        if (res.data) {
          this.sharedService.createToast(arrayAction);
        }
      });
  }
}
