import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { getContentFromProseMirrorJSON } from '../../helpers/functions/getContentFromProseMirror';
import { isValidIBAN } from 'ibantools';
import { titleBannedFillerWords } from '@libs/shared/vacancy/titleBannedFillerWords';
import { titleBannedTerms } from '@libs/shared/vacancy/titleBannedTerms';
import { titleBannedCharacters } from '@libs/shared/vacancy/titleBannedCharacters';

// const IBAN = require('iban');
export const customValidators = {
  required: (control: AbstractControl): ValidationErrors | null => {
    if (Validators.required(control)) {
      return {
        message: {
          key: 'fieldRequired',
        },
        missingRequiredField: true,
      };
    }
    return null;
  },
  minLength: (min: number): any => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.value.toString().split(' ').join('').length < min
      ) {
        return {
          message: {
            key: 'minLength',
            options: { min },
          },
          notEnoughCharacters: true,
        };
      }
      return null;
    };
  },
  maxLength: (max: number): any => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.value.toString().split(' ').join('').length > max
      ) {
        return {
          message: {
            key: 'maxLength',
            options: { max },
          },
          exceedingMaxLength: true,
        };
      }
      return null;
    };
  },
  email: (control: AbstractControl): ValidationErrors | null => {
    if (Validators.email(control)) {
      return {
        message: {
          key: 'invalidEmail',
        },
        invalidEmail: true,
      };
    }
    return null;
  },

  isEmpty: (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return !value || value === 'null' || value.length === 0
      ? { isEmpty: true }
      : null;
  },

  hasPostMirrorContent: (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const postMirrorContent = getContentFromProseMirrorJSON(value);
    return !postMirrorContent || postMirrorContent.length === 0
      ? { isEmpty: true }
      : null;
  },

  vacancyTitleDoubledTermsProvidedByOtherFields(): ValidatorFn {
    return (control: AbstractControl) => {
      const terms = control.value?.split(' ');
      if (!terms) {
        return null;
      }
      return titleBannedTerms.some((term) => terms.includes(term))
        ? { titleBannedTerms: true }
        : null;
    };
  },

  vacancyTitleBannedFillerWords(): ValidatorFn {
    return (control: AbstractControl) => {
      // checks if any of the banned filler words are in the title
      return titleBannedFillerWords.some((word) =>
        control.value?.toLowerCase().includes(word),
      )
        ? { bannedFillerWordsOrCharacters: true }
        : null;
    };
  },

  vacancyTitleBannedCharacters(): ValidatorFn {
    return (control: AbstractControl) => {
      // check if any banned characters are in the title
      return titleBannedCharacters.some((char) => control.value?.includes(char))
        ? { bannedFillerWordsOrCharacters: true }
        : null;
    };
  },

  validateIban: (control: AbstractControl) => {
    if (control.value && control.enabled) {
      if (!isValidIBAN(control.value.replace(/ /g, ''))) {
        return {
          message: {
            key: 'invalidIBAN',
          },
        };
      }
    }
    return null;
  },

  validateAHV: (control: AbstractControl) => {
    // 756.9217.0769.85 sample AHV number
    if (control.value && control.enabled) {
      if (!isValidAHV(control.value)) {
        return {
          message: {
            key: 'invalidAHV',
          },
        };
      }
    }
    return null;
  },
};

export function isValidAHV(number: string) {
  const regex = /[7][5][6][.][\d]{4}[.][\d]{4}[.][\d]{2}$/;
  let isValid = false;

  //Last digit of the entered number
  const checknumber = parseInt(number[number.length - 1]);

  //Validate the general setup of the insurance-number using the regex defined above
  isValid = regex.test(number);
  if (isValid) {
    //Remove last character (not needed to calculate checksum)
    let tmp_number: any = number.substring(0, number.length - 1);

    //Remove dots from number and reverse it
    tmp_number = tmp_number.split('.').join('').split('').reverse().join('');
    let sum = 0;
    for (let i = 0; i < tmp_number.length; i++) {
      const add = i % 2 == 0 ? tmp_number[i] * 3 : tmp_number[i] * 1;
      sum += add;
    }

    //Calculate correct checksum (https://www.sozialversicherungsnummer.ch/aufbau-neu.htm)
    const checksum = Math.ceil(sum / 10) * 10 - sum;
    if (checksum !== checknumber) {
      isValid = false;
    }
  }
  return isValid;
}

export function isValidPhoneNumber(number: string) {
  const fullRegexp = /^[+]?[(]?\d{1,3}[)]?[-\s.]?\d{3,12}$/;
  // remove spaces, dots, dashes and brackets
  number = number.replace(/[\s.\-()]/g, '');
  return fullRegexp.test(number);
}

export function isValidEmail(email: string) {
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
  return emailRegex.test(email);
}

export function isValidUrl(url: string) {
  const urlRegex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  return urlRegex.test(url);
}
