import {
  ArrayActionEnum,
  TalentUpdateInput,
  WorkArrangementEnum,
} from '../../../../graphql/generated';

export function getWorkArrangementsChanges(
  source: WorkArrangementEnum[],
  input: WorkArrangementEnum[],
): TalentUpdateInput['workArrangement'] | null {
  // input has the new value
  const newWorkArrangement = input
    .filter((item) => !source.includes(item))
    ?.at(0);
  if (newWorkArrangement) {
    return [
      {
        type: ArrayActionEnum.ADDED,
        uuid: newWorkArrangement,
        arrangement: newWorkArrangement,
      },
    ];
  }

  // input has the removed value
  const removedWorkArrangement = source
    .filter((item) => !input.includes(item))
    ?.at(0);
  if (removedWorkArrangement) {
    return [
      {
        type: ArrayActionEnum.REMOVED,
        uuid: removedWorkArrangement,
        arrangement: removedWorkArrangement,
      },
    ];
  }
  return null;
}
