import { inject, Injectable } from '@angular/core';
import { TalentFormEditService } from './talentFormEdit.service';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';
import { FormArray, FormGroup } from '@angular/forms';
import { ArrayActionEnum } from '../../../../graphql/generated';
import { getFormData } from '../../../helpers/functions/getFormData';

@Injectable()
export class TalentEmailPhoneService {
  formService = inject(TalentFormEditService);
  toastService = inject(ToastService);
  i18next = inject(I18NextPipe);

  createToast(arrayActionEnum: ArrayActionEnum) {
    switch (arrayActionEnum) {
      case ArrayActionEnum.REMOVED:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18next.transform('successfullyRemoved'),
          duration: 6000,
        });
        return;
      case ArrayActionEnum.ADDED:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18next.transform('successfullyAdded'),
          duration: 6000,
        });
        return;
      default:
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18next.transform('successfullyUpdated'),
          duration: 6000,
        });
    }
  }

  setPrimaryEntry<T extends FormGroup>(
    currentPrimary: T,
    formArray: FormArray<T>,
    key: 'emails' | 'phones',
    successfullyUpdated: (newPrimaryUuid: string) => void,
  ) {
    const primaryEntry = formArray?.controls.find(
      (item: any) => item.controls.isPrimary.value === true,
    ) as T;
    if (!primaryEntry) {
      console.error('Primary entry not found----this should not happen!');
      return;
    }
    // unset the current primary entry
    primaryEntry.controls.isPrimary.setValue(false);
    const currentPrimaryData = getFormData(primaryEntry);

    if (!currentPrimaryData) {
      console.error('currentPrimaryData not valid --- should not happen');
      return;
    }

    // Set the new primary entry to be the primary entry
    currentPrimary.controls.isPrimary.setValue(true);
    const newPrimaryEntry = getFormData(currentPrimary);

    if (!newPrimaryEntry) {
      console.error('newPrimaryEntry Data not valid --- should not happen');
      return;
    }

    this.formService
      .updateTalentApi({
        [key]: [currentPrimaryData, newPrimaryEntry],
      })
      .subscribe((res) => {
        if (res.data) {
          successfullyUpdated(newPrimaryEntry.uuid);
        }
      });
  }

  deletePrimaryEntry<T extends FormGroup>(
    currentPrimary: T,
    newPrimary: FormGroup,
    formArray: FormArray<T>,
    key: 'emails' | 'phones',
    successfullyUpdated: () => void,
  ) {
    const currentPrimaryData = getFormData(currentPrimary);
    const newPrimaryData = getFormData(newPrimary);
    if (!currentPrimaryData || !newPrimaryData) {
      console.error('Phone data is not valid ---- this should not happen!');
      return;
    }

    // Remove the current primary entry
    const index = formArray?.controls.indexOf(currentPrimary);
    formArray?.removeAt(index);
    currentPrimaryData.type = ArrayActionEnum.REMOVED;

    // Set the new primary entry to be the primary entry
    newPrimary.controls.isPrimary.setValue(true);
    newPrimaryData.isPrimary = true;

    this.formService
      .updateTalentApi({
        [key]: [currentPrimaryData, newPrimaryData],
      })
      .subscribe((res) => {
        if (res.data) {
          successfullyUpdated();
        }
      });
  }
}
