import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'yearToAge' })
export class YearToAgePipe implements PipeTransform {
  transform(date: Date | null): string {
    if (!date) {
      return '';
    }
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }
}
