import {
  ArrayActionEnum,
  DriversLicenseEnum,
  TalentUpdateInput,
} from '../../../../graphql/generated';

export function getDriversLicenseArrayChange(
  source: DriversLicenseEnum[],
  input: DriversLicenseEnum[],
): TalentUpdateInput['driversLicense'] | null {
  // input has the new value
  const newLicenses = input.filter((item) => !source.includes(item))?.at(0);
  if (newLicenses) {
    return [
      { type: ArrayActionEnum.ADDED, uuid: newLicenses, license: newLicenses },
    ];
  }

  // input has the removed value
  const removedLicenses = source.filter((item) => !input.includes(item))?.at(0);
  if (removedLicenses) {
    return [
      {
        type: ArrayActionEnum.REMOVED,
        uuid: removedLicenses,
        license: removedLicenses,
      },
    ];
  }
  return null;
}
