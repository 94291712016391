import { Injectable, signal } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  ArrayActionEnum,
  ContactEmailTypeEnum,
  ContactPhoneTypeEnum,
  CurrencyEnum,
  DriversLicenseEnum,
  MaritalStatus,
  ReligiousDenominationEnum,
  ResidencePermitEnum,
  SalaryUnitEnum,
  SexEnum,
  SupportedLanguage,
  TalentEmploymentTypeEnum,
  TalentFragment,
  TalentSourcePlatformEnum,
  TalentSourceTypeEnum,
  TalentUpdateGQL,
  TalentUpdateInput,
  WorkArrangementEnum,
  WorkAvailabilityEnum,
} from '../../../../graphql/generated';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { saveDebounceMs } from '../../../static/saveDebounceMs';
import { getObjectKeys } from '@libs/shared/helpers/getObjectKeys';
import { format } from 'date-fns';
import { DATE_TIME_BIRTHDATE } from '../../../../app.constants';
import { AddSpacesToIbanPipe } from '../../../helpers/pipes/addSpacesToIban.pipe';
import { customValidators } from '../../shared-forms/customValidators';
import { WritableDeep } from 'type-fest';
import {
  AddressFormGroup,
  AvailabilityFormGroup,
  ChildrenFormGroup,
  EmailFormGroup,
  JobPreferencesFormGroup,
  LinksFormGroup,
  PhonesFormGroup,
  PreferredLocationsFormGroup,
  RecommendationsFormGroup,
  SourcesFormGroup,
  TalentWriteable,
  TargetJobTitleFormGroup,
} from '../talent-sheet.types';
import { UserService } from '../../../../models/shared/user/user.service';
import { getDriversLicenseArrayChange } from '../../talent-changelog/helpers/getDriversLicenseArrayChange';
import { getWorkArrangementsChanges } from '../../talent-changelog/helpers/getWorkArrangementsChanges';

@Injectable()
export class TalentFormEditService {
  personalData = new FormGroup({
    firstName: new FormControl<string | null>(null),
    lastName: new FormControl<string | null>(null),
    sex: new FormControl<SexEnum | null>(null),
    dateOfBirth: new FormControl<Date[]>([]),
    placeOfBirth: new FormControl<string | null>(null),
    nationality: new FormControl<string | null>(null),
    civilOrigin: new FormControl<string | null>(null),
    hasVehicle: new FormControl<boolean>(false),
    religiousDenomination: new FormControl<ReligiousDenominationEnum | null>(
      null,
    ),
    maritalStatus: new FormControl<MaritalStatus | null>(null),
    ahvNumber: new FormControl<string | null>(null, {
      validators: [customValidators.validateAHV],
      updateOn: 'change',
    }),
    iban: new FormControl<string | null>(null, {
      validators: [customValidators.validateIban],
      updateOn: 'change',
    }),
    residencePermit: new FormControl<ResidencePermitEnum | null>(null),
    residencePermitValidUntil: new FormControl<Date[]>([]),
    avatar: new FormControl<{
      mediaObject: object;
      transformOptions: object;
    } | null>(null),
  });

  // driverLicense is not in PersonalDataFromGroup, because it has a different structure & has custom checks
  driversLicense = new FormControl<DriversLicenseEnum[] | null>(null);

  phonesFormArray = new FormArray<FormGroup<PhonesFormGroup>>([]);
  emailsFormArray = new FormArray<FormGroup<EmailFormGroup>>([]);
  addressesFormArray = new FormArray<FormGroup<AddressFormGroup>>([]);
  linksFormArray = new FormArray<FormGroup<LinksFormGroup>>([]);
  communicationLanguage = new FormControl<SupportedLanguage | null>(null);

  contact = new FormGroup({
    phones: this.phonesFormArray,
    emails: this.emailsFormArray,
    addresses: this.addressesFormArray,
    links: this.linksFormArray,
    communicationLanguage: this.communicationLanguage,
  });

  availability = new FormGroup<AvailabilityFormGroup>({
    workAvailability: new FormControl<WorkAvailabilityEnum | null>(null),
    availableForWorkAt: new FormControl<Date[] | null>([]),
    registeredWithRAV: new FormControl<boolean | null>(null),
    nonCompeteAgreement: new FormControl<boolean | null>(null),
    mustServeMilitaryService: new FormControl<boolean | null>(null),
    nextMilitaryServiceAt: new FormControl<Date[] | null>([]),
    availabilityNote: new FormControl<string | null>(null),
    blackListedReason: new FormControl<string | null>(null),
    employmentCompany: new FormControl<string | null>(null),
    employedSince: new FormControl<Date[] | null>([]),
    employedUntil: new FormControl<Date[] | null>([]),
  });

  targetJobTitleFormArray = new FormArray<FormGroup<TargetJobTitleFormGroup>>(
    [],
  );

  // workArrangement is not in jobPreferences FormGroup, because it has a different structure & has custom checks
  workArrangement = new FormControl<WorkArrangementEnum[] | null>(null);

  employmentType = new FormControl<TalentEmploymentTypeEnum | null>(null);
  workloadFlexible = new FormControl<boolean>(false);
  workloadPercentageMin = new FormControl<number | null>(null);
  workloadPercentageMax = new FormControl<number | null>(null);
  workloadPercentage = new FormControl<number | null>(null);
  salaryExpectationFlexible = new FormControl<boolean>(false);
  salaryExpectationUnit = new FormControl<SalaryUnitEnum | null>(null);
  salaryExpectationPaymentsPerYear = new FormControl<number | null>(null);
  salaryExpectationHoursPerWeek = new FormControl<number | null>(null);
  salaryExpectationMin = new FormControl<number | null>(null);
  salaryExpectationMax = new FormControl<number | null>(null);
  salaryExpectation = new FormControl<number | null>(null);
  salaryExpectationCurrency = new FormControl<CurrencyEnum | null>(null);
  maxCommutingTimeMinutes = new FormControl<number | null>(null);
  commutingByBicycle = new FormControl<boolean>(false);
  commutingByCar = new FormControl<boolean>(false);
  commutingByPublicTransport = new FormControl<boolean>(false);
  preferenceNote = new FormControl<string | null>(null);

  preferredLocations = new FormArray<FormGroup<PreferredLocationsFormGroup>>(
    [],
  );

  jobPreferences: FormGroup<JobPreferencesFormGroup> = new FormGroup({
    targetJobTitle: this.targetJobTitleFormArray,
    employmentType: this.employmentType,
    workloadFlexible: this.workloadFlexible,
    workloadPercentageMin: this.workloadPercentageMin,
    workloadPercentageMax: this.workloadPercentageMax,
    workloadPercentage: this.workloadPercentage,
    salaryExpectationFlexible: this.salaryExpectationFlexible,
    salaryExpectationUnit: this.salaryExpectationUnit,
    salaryExpectationMin: this.salaryExpectationMin,
    salaryExpectationMax: this.salaryExpectationMax,
    salaryExpectation: this.salaryExpectation,
    salaryExpectationCurrency: this.salaryExpectationCurrency,
    salaryExpectationPaymentsPerYear: this.salaryExpectationPaymentsPerYear,
    salaryExpectationHoursPerWeek: this.salaryExpectationHoursPerWeek,
    maxCommutingTimeMinutes: this.maxCommutingTimeMinutes,
    commutingByBicycle: this.commutingByBicycle,
    commutingByCar: this.commutingByCar,
    commutingByPublicTransport: this.commutingByPublicTransport,
    preferenceNote: this.preferenceNote,
  });

  internalNotes = new FormControl<string | null>(null);

  //--- Fields that are not autosaved --- start -----
  sources = new FormArray<FormGroup<SourcesFormGroup>>([]);
  children = new FormArray<FormGroup<ChildrenFormGroup>>([]);
  recommendations = new FormArray<FormGroup<RecommendationsFormGroup>>([]);
  //--- Fields that are not autosaved --- end -----

  talent = signal<TalentFragment | null>(null);

  focusedFields = new BehaviorSubject<Partial<Record<string, boolean>>>({});

  /**
   * This is used to prevent the form from updating/patching itself when the update is done programmatically
   *
   * (example): when adding new child to the children form, we don't want to update the form with the new child data coming from the server
   * because the user already added the child and the form is already updated
   */
  programmaticUpdate = new BehaviorSubject<boolean>(false);

  user$ = this.userService.user$;

  constructor(
    private talentUpdateGQL: TalentUpdateGQL,
    private addSpacesToIbanPipe: AddSpacesToIbanPipe,
    private userService: UserService,
  ) {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    this.subscribePersonalDataFormChanges();
    this.subscribeJobPreferenceFormChanges();
    this.subscribeInternalNotesFormChanges();
  }

  patchForm(talent: TalentFragment) {
    const clonedTalent = structuredClone(
      talent,
    ) as WritableDeep<TalentFragment>;
    if (!this.programmaticUpdate.value) {
      this.patchPersonalDataForm(clonedTalent);
      this.patchChildrenForm(clonedTalent.children);
      this.patchContactForm(clonedTalent);
      this.patchAvailabilityDataForm(clonedTalent);
      this.patchJobPreferencesDataForm(clonedTalent);
      this.patchPreferredLocationsForm(clonedTalent.preferredLocations);
      this.patchSourcesForm(clonedTalent.sources);
      this.patchRecommendationsForm(clonedTalent.recommendedBy);
      this.patchInternalNotesForm(clonedTalent.internalNotes);
    }

    // allows for resolved field "sizes" to be reapplied to the avatar if changed
    this.patchPersonalDataAvatarForm(clonedTalent);
    this.talent.set(talent);
    this.programmaticUpdate.next(false);
  }

  patchPersonalDataForm(talent: TalentWriteable) {
    const values: Partial<Record<keyof TalentWriteable, any>> = {};
    getObjectKeys(this.personalData.controls).forEach((key) => {
      const fieldIsCurrentlyFocused = this.focusedFields.value[key];
      if (!fieldIsCurrentlyFocused) {
        switch (key) {
          case 'residencePermitValidUntil':
          case 'dateOfBirth': {
            const date = talent[key];
            values[key] = date ? new Date(date) : [];
            break;
          }
          case 'iban': {
            const ibanValue = talent[key];
            if (ibanValue === null) {
              values[key] = null;
              break;
            } else {
              values[key] = this.addSpacesToIbanPipe.transform(ibanValue);
            }
            break;
          }
          default:
            values[key] = talent[key];
        }
      }
    });
    this.personalData.patchValue(values, { emitEvent: false });

    this.driversLicense.patchValue(
      talent.driversLicense.map((v) => v.license),
      { emitEvent: false },
    );
  }

  patchAvailabilityDataForm(talent: TalentWriteable) {
    const values: Partial<Record<keyof TalentWriteable, any>> = {};
    getObjectKeys(this.availability.controls).forEach((key) => {
      const fieldIsCurrentlyFocused = this.focusedFields.value[key];
      if (!fieldIsCurrentlyFocused) {
        switch (key) {
          case 'employedSince':
          case 'employedUntil':
          case 'availableForWorkAt':
          case 'nextMilitaryServiceAt': {
            const date = talent[key];
            values[key] = date ? new Date(date) : [];
            break;
          }
          default:
            values[key] = talent[key];
        }
      }
    });
    this.availability.patchValue(values, { emitEvent: false });

    this.checkUserHasPermissionToEditAvailability(talent);
  }

  patchJobPreferencesDataForm(talent: TalentWriteable) {
    const values: Partial<Record<keyof TalentWriteable, any>> = {};
    getObjectKeys(this.jobPreferences.controls).forEach((key) => {
      const fieldIsCurrentlyFocused = this.focusedFields.value[key];
      if (!fieldIsCurrentlyFocused) {
        switch (key) {
          case 'targetJobTitle':
            this.patchArray(
              this.talent()?.[key] || [],
              talent[key] || [],
              (item) => this.addNewTargetJobTitle(item),
              (index) => this.targetJobTitleFormArray.removeAt(index),
              (items) => this.updateItems(items, this.targetJobTitleFormArray),
            );
            break;
          case 'salaryExpectationUnit':
            values[key] = talent[key] || SalaryUnitEnum.MONTH;
            break;
          case 'salaryExpectationHoursPerWeek':
            values[key] = talent[key] || 42;
            break;
          case 'salaryExpectationPaymentsPerYear':
            values[key] = talent[key] || 12;
            break;
          default:
            values[key] = talent[key];
        }
      }
    });
    this.jobPreferences.patchValue(values, { emitEvent: false });

    this.workArrangement.patchValue(
      talent.workArrangement.map((v) => v.arrangement),
      { emitEvent: false },
    );
  }

  patchInternalNotesForm(internalNotes: TalentWriteable['internalNotes']) {
    this.internalNotes.patchValue(internalNotes, { emitEvent: false });
  }

  checkUserHasPermissionToEditAvailability(talent: TalentWriteable) {
    const userHasPermissionToEditAvailability = true; // TODO: implement this permission check with a follow up task
    if (userHasPermissionToEditAvailability) return;
    if (talent.workAvailability === WorkAvailabilityEnum.BLACKLISTED) {
      this.availability.controls.blackListedReason.disable({
        emitEvent: false,
      });
      this.availability.controls.workAvailability.disable({ emitEvent: false });
    }
  }

  patchChildrenForm(children: TalentWriteable['children']) {
    this.patchArray(
      this.talent()?.children || [],
      children || [],
      (item) => this.addNewChild(item),
      (index) => this.children.removeAt(index),
      (items) => this.updateItems(items, this.children),
    );
  }

  patchPreferredLocationsForm(
    preferredLocations: TalentWriteable['preferredLocations'],
  ) {
    this.patchArray(
      this.talent()?.preferredLocations || [],
      preferredLocations || [],
      (item) => this.addNewPreferredLocation(item),
      (index) => this.preferredLocations.removeAt(index),
      (items) => this.updateItems(items, this.preferredLocations),
    );
  }

  patchSourcesForm(sources: TalentWriteable['sources']) {
    this.patchArray(
      this.talent()?.sources || [],
      sources || [],
      (item) => this.addNewSource(item),
      (index) => this.sources.removeAt(index),
      (items) => this.updateItems(items, this.sources),
    );
  }

  patchRecommendationsForm(recommendations: TalentWriteable['recommendedBy']) {
    this.patchArray(
      this.talent()?.recommendedBy || [],
      recommendations || [],
      (item) => this.addNewRecommendation(item),
      (index) => this.recommendations.removeAt(index),
      (items) => this.updateItems(items, this.recommendations),
    );
  }

  patchArray<T extends { uuid: string }, U extends { uuid: string }>(
    currentArray: readonly U[],
    newArray: T[],
    addNewItem: (item: T) => void,
    removeItem: (index: number) => void,
    updateItems: (items: readonly T[]) => void,
  ) {
    if (currentArray.length === 0 || newArray.length > currentArray.length) {
      // New items added
      for (const item of newArray) {
        const existingItem = currentArray.find((c) => c.uuid === item.uuid);
        if (!existingItem) {
          addNewItem(item);
        }
      }
    } else if (newArray.length < currentArray.length) {
      // Items removed
      const removedItems = currentArray.filter(
        (item) => !newArray.some((newItem) => newItem.uuid === item.uuid),
      );
      for (const removedItem of removedItems) {
        const removedItemIndex = currentArray.findIndex(
          (item) => item.uuid === removedItem.uuid,
        );
        if (removedItemIndex === -1) {
          console.error('Item not found in array:', removedItem);
        }
        removeItem(removedItemIndex);
      }
    } else {
      // Items updated
      updateItems(newArray);
    }
  }

  patchContactForm(talent: TalentWriteable) {
    const values: Partial<Record<keyof TalentWriteable, any>> = {};
    getObjectKeys(this.contact.controls).forEach((key) => {
      const fieldIsCurrentlyFocused = this.focusedFields.value[key];
      if (!fieldIsCurrentlyFocused) {
        switch (key) {
          case 'links':
            this.patchArray(
              this.talent()?.links || [],
              talent.links || [],
              (item) => this.addNewLink(item),
              (index) => this.linksFormArray.removeAt(index),
              (items) => this.updateItems(items, this.linksFormArray),
            );
            break;
          case 'emails': {
            this.patchArray(
              this.talent()?.emails || [],
              talent.emails || [],
              (item) => this.addNewEmail(item),
              (index) => this.emailsFormArray.removeAt(index),
              (items) => this.updateItems(items, this.emailsFormArray),
            );
            const primaryEmailUuid = talent.emails.find(
              (email) => email.isPrimary,
            )?.uuid;
            this.setPrimaryEmailFirst(primaryEmailUuid);
            break;
          }
          case 'phones': {
            this.patchArray(
              this.talent()?.phones || [],
              talent.phones || [],
              (item) => this.addNewPhone(item),
              (index) => this.phonesFormArray.removeAt(index),
              (items) => this.updateItems(items, this.phonesFormArray),
            );
            const primaryUuid = talent.phones.find(
              (phone) => phone.isPrimary,
            )?.uuid;
            this.setPrimaryPhoneFirst(primaryUuid);
            break;
          }
          case 'addresses':
            this.patchArray(
              this.talent()?.addresses || [],
              talent.addresses || [],
              (item) => this.addNewAddress(item),
              (index) => this.addressesFormArray.removeAt(index),
              (items) => this.updateItems(items, this.addressesFormArray),
            );
            break;
          default:
            values[key] = talent[key];
        }
      }
    });
    this.contact.patchValue(values, { emitEvent: false });
  }

  patchPersonalDataAvatarForm(talent: TalentWriteable) {
    const avatar = talent.avatar;
    if (avatar !== null) {
      this.personalData.controls.avatar.patchValue(
        {
          mediaObject: avatar.mediaObject,
          transformOptions: avatar.transformOptions ?? {},
        },
        {
          emitEvent: false,
        },
      );
    } else {
      this.personalData.controls.avatar.patchValue(null, {
        emitEvent: false,
      });
    }
  }

  updateTalentApi(input: Omit<TalentUpdateInput, 'uuid'>) {
    this.programmaticUpdate.next(true);

    const uuid = this.talent()?.uuid;
    if (!uuid) throw new Error('No current talent');

    return this.talentUpdateGQL.mutate({
      input: {
        ...input,
        uuid,
      },
    });
  }

  subscribePersonalDataFormChanges() {
    this.personalData.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(() => this.personalData.dirty),
      )
      .subscribe(() => {
        this.updatePersonalData();
      });

    this.driversLicense.valueChanges.subscribe((licenses) => {
      const currentLicenses =
        this.talent()?.driversLicense?.map((v) => v.license) || [];
      const input = getDriversLicenseArrayChange(
        currentLicenses,
        licenses || [],
      );
      if (!input) {
        console.error(
          'No Changes found in the driversLicense.valueChanges , which should not happen',
        );
        return;
      }
      this.submitFieldUpdate('driversLicense', input);
    });
  }

  subscribeJobPreferenceFormChanges() {
    this.jobPreferences.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(() => this.jobPreferences.dirty),
      )
      .subscribe((value) => {
        getObjectKeys(value).forEach((key) => {
          const control = this.jobPreferences.controls[key];
          if (!control.dirty) return;
          this.submitFieldUpdate(key, this.transformControlValue(control, key));
          control.markAsPristine();
        });
      });

    this.workArrangement.valueChanges.subscribe((workArrangements) => {
      const currentArrangments =
        this.talent()?.workArrangement?.map((v) => v.arrangement) || [];
      const input = getWorkArrangementsChanges(
        currentArrangments,
        workArrangements || [],
      );
      if (!input) {
        console.error(
          'No Changes found in the workArrangements.valueChanges , which should not happen',
        );
        return;
      }
      this.submitFieldUpdate('workArrangement', input);
    });
  }

  subscribeInternalNotesFormChanges() {
    this.internalNotes.valueChanges
      .pipe(
        debounceTime(saveDebounceMs),
        filter(() => this.internalNotes.dirty),
      )
      .subscribe(() => {
        this.submitFieldUpdate('internalNotes', this.internalNotes.value);
        this.internalNotes.markAsPristine();
      });
  }

  updatePersonalData() {
    const controls = this.personalData.controls;
    getObjectKeys(controls).forEach((key) => {
      const control = this.personalData.controls[key];
      if (control instanceof FormControl && control.dirty && control.valid) {
        this.submitFieldUpdate(key, this.transformControlValue(control, key));
        control.markAsPristine();
      } else if (control instanceof FormArray) {
        throw Error('FormArray not supported yet please implement it');
      }
    });
  }

  transformControlValue(control: AbstractControl, key: keyof TalentWriteable) {
    const value = control.value;
    switch (key) {
      case 'workloadPercentageMin':
      case 'workloadPercentageMax':
      case 'workloadPercentage':
      case 'salaryExpectation':
      case 'salaryExpectationMin':
      case 'salaryExpectationMax':
      case 'salaryExpectationHoursPerWeek':
      case 'maxCommutingTimeMinutes':
        return parseInt(value);
      case 'availableForWorkAt':
      case 'nextMilitaryServiceAt':
      case 'residencePermitValidUntil':
      case 'employedSince':
      case 'employedUntil':
      case 'dateOfBirth':
        return this.transformDatesToISOStrings(
          value as Date[],
          DATE_TIME_BIRTHDATE,
        );
      case 'avatar':
        if (value?.mediaObject?.uuid) {
          return {
            mediaObject: {
              uuid: value?.mediaObject?.uuid,
              path: value?.mediaObject?.path,
              context: value?.mediaObject?.context,
              uploadedBy: value?.mediaObject?.uploadedBy,
              createdAt: new Date().toISOString(),
              metadata: {
                height: value?.mediaObject?.metadata?.height,
                mimeType: value?.mediaObject?.metadata?.mimeType,
                size: value?.mediaObject?.metadata?.size,
                width: value?.mediaObject?.metadata?.width,
              },
            },
            transformOptions: value?.transformOptions ?? {},
          };
        } else {
          return null;
        }
      default:
        return value;
    }
  }

  transformDatesToISOStrings(
    dates: Date[] | null,
    formatString: string = DATE_TIME_BIRTHDATE,
  ) {
    if (!dates?.length) return null;
    return format(dates[0], formatString);
  }

  submitFieldUpdate(key: keyof TalentUpdateInput, value: any) {
    if (!this.talent) throw new Error('No current talent');
    this.updateTalentApi({
      [key]: value,
    }).subscribe();
  }

  addNewChild(child: TalentWriteable['children'][number]) {
    this.children.push(
      new FormGroup<ChildrenFormGroup>({
        uuid: new FormControl<string | null>(child.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        firstName: new FormControl<string | null>(child.firstName),
        lastName: new FormControl<string | null>(child.lastName),
        yearOfBirth: new FormControl<Date[] | null>([
          new Date(child.yearOfBirth),
        ]),
        hasChildAllowance: new FormControl<boolean | null>(
          child.hasChildAllowance,
        ),
        isNewEntry: new FormControl<boolean | null>(false),
      }),
      { emitEvent: false },
    );
  }

  addNewPhone(item: TalentWriteable['phones'][number]) {
    this.phonesFormArray.push(
      new FormGroup<PhonesFormGroup>({
        uuid: new FormControl<string | null>(item.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        phone: new FormControl<string | null>(item.phone),
        isPrimary: new FormControl<boolean | null>(item.isPrimary),
        phoneType: new FormControl<ContactPhoneTypeEnum | null>(item.phoneType),
      }),
      { emitEvent: false },
    );
  }

  addNewEmail(item: TalentWriteable['emails'][number]) {
    this.emailsFormArray.push(
      new FormGroup<EmailFormGroup>({
        uuid: new FormControl<string | null>(item.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        email: new FormControl<string | null>(item.email),
        isPrimary: new FormControl<boolean | null>(item.isPrimary),
        emailType: new FormControl<ContactEmailTypeEnum | null>(item.emailType),
      }),
      { emitEvent: false },
    );
  }

  addNewAddress(address: TalentWriteable['addresses'][number]) {
    this.addressesFormArray.push(
      new FormGroup<AddressFormGroup>({
        uuid: new FormControl<string | null>(address.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        streetAndNumber: new FormControl<string | null>(
          address.streetAndNumber,
        ),
        zip: new FormControl<string | null>(address.zip),
        location: new FormControl<string | null>(address.location),
        country: new FormControl<string | null>(address.country),
        isNewEntry: new FormControl<boolean | null>(false),
      }),
      { emitEvent: false },
    );
  }

  addNewLink(link: TalentWriteable['links'][number]) {
    this.linksFormArray.push(
      new FormGroup<LinksFormGroup>({
        uuid: new FormControl<string | null>(link.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        url: new FormControl<string | null>(link.url),
      }),
      { emitEvent: false },
    );
  }

  addNewTargetJobTitle(
    targetJobTitle: TalentWriteable['targetJobTitle'][number],
  ) {
    this.targetJobTitleFormArray.push(
      new FormGroup<TargetJobTitleFormGroup>({
        uuid: new FormControl<string | null>(targetJobTitle.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        title: new FormControl<string | null>(targetJobTitle.title),
      }),
      { emitEvent: false },
    );
  }

  addNewPreferredLocation(
    preferredLocation: TalentWriteable['preferredLocations'][number],
  ) {
    this.preferredLocations.push(
      new FormGroup<PreferredLocationsFormGroup>({
        uuid: new FormControl<string | null>(preferredLocation.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        location: new FormControl<string | null>(preferredLocation.location),
        radiusKm: new FormControl<number | null>(preferredLocation.radiusKm),
      }),
      { emitEvent: false },
    );
  }

  addNewSource(source: TalentWriteable['sources'][number]) {
    this.sources.push(
      new FormGroup<SourcesFormGroup>({
        uuid: new FormControl<string>(source.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        platform: new FormControl<TalentSourcePlatformEnum | null>(
          source.platform,
        ),
        contactAt: new FormControl<Date[] | null>([new Date(source.contactAt)]),
        sourceType: new FormControl<TalentSourceTypeEnum | null>(
          source.sourceType,
        ),
        isNewEntry: new FormControl<boolean>(false),
      }),
      { emitEvent: false },
    );
  }

  addNewRecommendation(source: TalentWriteable['recommendedBy'][number]) {
    this.recommendations.push(
      new FormGroup<RecommendationsFormGroup>({
        uuid: new FormControl<string>(source.uuid),
        type: new FormControl<ArrayActionEnum | null>(ArrayActionEnum.CHANGED),
        firstName: new FormControl<string | null>(source.firstName),
        lastName: new FormControl<string | null>(source.lastName),
        email: new FormControl<string | null>(source.email),
        isNewEntry: new FormControl<boolean>(false),
      }),
      { emitEvent: false },
    );
  }

  updateItems<T extends { uuid: string }, U extends FormArray<FormGroup<any>>>(
    array: readonly T[],
    formArray: U,
  ) {
    for (const item of array) {
      const itemControl = formArray.controls.find(
        (c) => c.value.uuid === item.uuid,
      );
      if (!itemControl) {
        throw new Error('Item control not found');
      }
      itemControl.patchValue(
        {
          ...item,
          type: ArrayActionEnum.CHANGED,
        },
        { emitEvent: false },
      );
    }
  }

  setPrimaryEmailFirst(primaryUuid: string | undefined) {
    if (!primaryUuid) return;
    // primary phone should be first
    const primaryIndex = this.emailsFormArray.controls.findIndex(
      (control) => control.value?.uuid === primaryUuid,
    );
    if (primaryIndex !== 0) {
      const primaryEmail = this.emailsFormArray.controls[primaryIndex];
      this.emailsFormArray.removeAt(primaryIndex);
      this.emailsFormArray.insert(0, primaryEmail);
    }
  }

  setPrimaryPhoneFirst(primaryUuid: string | undefined) {
    if (!primaryUuid) return;
    // primary phone should be first
    const primaryIndex = this.phonesFormArray.controls.findIndex(
      (control) => control.value?.uuid === primaryUuid,
    );
    if (primaryIndex !== 0) {
      const primaryPhone = this.phonesFormArray.controls[primaryIndex];
      this.phonesFormArray.removeAt(primaryIndex);
      this.phonesFormArray.insert(0, primaryPhone);
    }
  }
}
