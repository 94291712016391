import { FormGroup } from '@angular/forms';

export function getFormData<T extends FormGroup>(control: T) {
  const values = Object.values(control.value);

  const valuesAreInvalid = values.every((value) => {
    if (typeof value === 'boolean') {
      return value === undefined || value === null;
    } else {
      return !value;
    }
  });

  if (valuesAreInvalid) {
    console.error('Form control is not valid');
    return undefined;
  }

  const data: any = {};
  for (const key of Object.keys(control.value)) {
    data[key] = control.controls[key].value;
  }
  return data;
}
